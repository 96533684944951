import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = 'https://education.ztxinde.com/'
// axios.defaults.baseURL = 'https://lp.ztxinde.com/'



//请求拦截器
axios.interceptors.request.use(function (config) {
    config.headers['Token'] = sessionStorage.getItem('Token')
    // config.headers['Token'] = 'adf7cbdcdc62b07d94f86339e5687ca51'
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 导出接口数据

// --------------------------------------------------------------------------------------
// 登录接口
export const getLoginApi = params => {
    return axios.get('login_in', { params }).then(res => res.data)
}
// 退出接口
export const loginOutApi = params => {
    return axios.get('login_out', { params }).then(res => res.data)
}
// -------------------------------------------------------------------------------------
// 首页顶部接口
export const topIndexApi = params => {
    return axios.get('index_top', { params }).then(res => res.data)
}
// 首页左侧接口
export const leftIndexApi = params => {
    return axios.get('index_middle_left', { params }).then(res => res.data)
}
// 首页右侧接口
export const rightIndexApi = params => {
    return axios.get('index_middle_right', { params }).then(res => res.data)
}
// 首页底部接口
export const bottomIndexApi = params => {
    return axios.get('index_bottom', { params }).then(res => res.data)
}
// ----------------------------------------------------------------------------------
// 角色列表首页展示
export const getRoleApi = params => {
    return axios.get('auth_user_index', { params }).then(res => res.data)
}
// 角色列表添加修改展示数据
export const showRoleApi = params => {
    return axios.get('auth_user_data', { params }).then(res => res.data)
}
// 角色列表添加数据
export const addRoleApi = params => {
    return axios.get('auth_user_add', { params }).then(res => res.data)
}
// 角色列表修改数据
export const editRoleApi = params => {
    return axios.get('auth_user_mdify', { params }).then(res => res.data)
}
// 角色列表删除数据
export const delectRoleApi = params => {
    return axios.get('auth_user_remove', { params }).then(res => res.data)
}
// ---------------------------------------------------------------------------------
// 权限组首页展示数据
export const getGroupApi = params => {
    return axios.get('group_index', { params }).then(res => res.data)
}
// 权限组添加修改获取数据
export const showGroupApi = params => {
    return axios.get('group_data', { params }).then(res => res.data)
}
// 权限组添加数据
export const addGroupApi = params => {
    return axios.get('group_add', { params }).then(res => res.data)
}
// 权限组修改数据
export const editGroupApi = params => {
    return axios.get('group_mdify', { params }).then(res => res.data)
}
// 权限组删除数据
export const delGroupApi = params => {
    return axios.get('group_remove', { params }).then(res => res.data)
}
// ----------------------------------------------------------------------
// 权限首页数据展示
export const getAuthApi = params => {
    return axios.get('rule_index', { params }).then(res => res.data)
}
// 权限添加修改获取数据
export const showAuthApi = params => {
    return axios.get('rule_data', { params }).then(res => res.data)
}
// 权限添加数据
export const addAuthApi = params => {
    return axios.get('rule_add', { params }).then(res => res.data)
}
// 权限修改数据
export const editAuthApi = params => {
    return axios.get('rule_mdify', { params }).then(res => res.data)
}
// 权限删除数据
export const delAuthApi = params => {
    return axios.get('rule_remove', { params }).then(res => res.data)
}
// ---------------------------------------------------------------------------
// 分校管理首页数据展示
export const getBranchApi = params => {
    return axios.get('campus_index', { params }).then(res => res.data)
}
// 分校管理添加修改数据展示
export const showBranchApi = params => {
    return axios.get('campus_data', { params }).then(res => res.data)
}
// 分校管理添加数据
export const addBranchApi = params => {
    return axios.get('campus_add', { params }).then(res => res.data)
}
// 分校管理修改数据
export const editBranchApi = params => {
    return axios.get('campus_mdify', { params }).then(res => res.data)
}
// 分校管理删除数据
export const delBranchApi = params => {
    return axios.get('campus_remove', { params }).then(res => res.data)
}
// ----------------------------------------------------------------------------
// 校区人员管理  运营首页展示
export const getAmherstApi = params => {
    return axios.get('campus_mana_index', { params }).then(res => res.data)
}
// 校区人员管理  运营添加修改展示
export const showAmherstApi = params => {
    return axios.get('campus_mana_data', { params }).then(res => res.data)
}
// 校区人员管理  运营添加
export const addAmherstApi = params => {
    return axios.get('campus_mana_add', { params }).then(res => res.data)
}
// 校区人员管理  运营修改
export const editAmherstApi = params => {
    return axios.get('campus_mana_mdify', { params }).then(res => res.data)
}
// 校区人员管理  运营删除
export const delAmherstApi = params => {
    return axios.get('campus_mana_remove', { params }).then(res => res.data)
}
// -------------------------------------------------------------------------------
// 校区人员管理  老师首页展示
export const getTeacherApi = params => {
    return axios.get('campus_person_index', { params }).then(res => res.data)
}
// 校区人员管理  老师添加修改展示
export const showTeacherApi = params => {
    return axios.get('campus_person_data', { params }).then(res => res.data)
}
// 校区人员管理  老师添加
export const addTeacherApi = params => {
    return axios.get('campus_person_add', { params }).then(res => res.data)
}
// 校区人员管理  老师修改
export const editTeacherApi = params => {
    return axios.get('campus_person_mdify', { params }).then(res => res.data)
}
// 校区人员管理  老师删除
export const delTeacherApi = params => {
    return axios.get('campus_person_remove', { params }).then(res => res.data)
}
// ---------------------------------------------------------------------------------
// 班级类型管理首页展示
export const getClassTyprApi = params => {
    return axios.get('campus_class_type_index', { params }).then(res => res.data)
}
// 班级类型管理  添加修改展示
export const showClassTyprApi = params => {
    return axios.get('campus_class_type_data', { params }).then(res => res.data)
}
// 班级类型管理  添加
export const addClassTyprApi = params => {
    return axios.get('campus_class_type_add', { params }).then(res => res.data)
}
// 班级类型管理  修改
export const editClassTyprApi = params => {
    return axios.get('campus_class_type_mdify', { params }).then(res => res.data)
}
// 班级类型管理  删除
export const delClassTyprApi = params => {
    return axios.get('campus_class_type_remove', { params }).then(res => res.data)
}
// -----------------------------------------------------------------------------------
// 班级管理  首页展示
export const getClassRunApi = params => {
    return axios.get('campus_class_index', { params }).then(res => res.data)
}
// 班级管理  添加修改展示
export const showClassRunApi = params => {
    return axios.get('campus_class_data', { params }).then(res => res.data)
}
// 班级管理  获取下拉数据
export const selClassRunApi = params => {
    return axios.get('campus_class_select', { params }).then(res => res.data)
}
// 班级管理  添加
export const addClassRunApi = params => {
    return axios.get('campus_class_add', { params }).then(res => res.data)
}
// 班级管理  修改
export const editClassRunApi = params => {
    return axios.get('campus_class_mdify', { params }).then(res => res.data)
}
// 班级管理  删除
export const delClassRunApi = params => {
    return axios.get('campus_class_remove', { params }).then(res => res.data)
}
// --------------------------------------------------------------------------
// 家长列表  首页展示
export const getParentApi = params => {
    return axios.get('campus_parent_index', { params }).then(res => res.data)
}
// 家长列表  添加修改展示
export const showParentApi = params => {
    return axios.get('campus_parent_data', { params }).then(res => res.data)
}
// 家长列表  下拉
export const selParentApi = params => {
    return axios.get('campus_parent_select', { params }).then(res => res.data)
}
// 家长列表  添加
export const addParentApi = params => {
    return axios.get('campus_parent_add', { params }).then(res => res.data)
}
// 家长列表  修改
export const editParentApi = params => {
    return axios.get('campus_parent_mdify', { params }).then(res => res.data)
}
// 家长列表  删除
export const removeParentApi = params => {
    return axios.get('campus_parent_remove', { params }).then(res => res.data)
}
// -----------------------------------------------------------------------------------
// 学生列表  首页展示
export const getStudetApi = params => {
    return axios.get('campus_student_index', { params }).then(res => res.data)
}
// 学生列表  添加修改展示
export const showStudetApi = params => {
    return axios.get('campus_student_data', { params }).then(res => res.data)
}
// 学生列表  下拉
export const selStudetApi = params => {
    return axios.get('campus_student_select', { params }).then(res => res.data)
}
// 学生列表  添加
export const addStudetApi = params => {
    return axios.get('campus_student_add', { params }).then(res => res.data)
}
// 学生列表  修改
export const editStudetApi = params => {
    return axios.get('campus_student_mdify', { params }).then(res => res.data)
}
// 学生列表  删除
export const removeStudetApi = params => {
    return axios.get('campus_student_remove', { params }).then(res => res.data)
}
// --------------------------------------------------------------------------------------
// 用户列表  首页展示
export const getUserApi = params => {
    return axios.get('user_index', { params }).then(res => res.data)
}
// --------------------------------------------------------------------------------
// 信息管理  首页展示
export const getManageApi = params => {
    return axios.get('msg_index', { params }).then(res => res.data)
}
// 信息管理  添加修改展示
export const showManageApi = params => {
    return axios.get('msg_data', { params }).then(res => res.data)
}
// 信息管理  下拉
export const selManageApi = params => {
    return axios.get('msg_data_select', { params }).then(res => res.data)
}
// 信息管理  添加
export const addManageApi = params => {
    return axios.get('msg_add', { params }).then(res => res.data)
}
// 信息管理  修改
export const editManageApi = params => {
    return axios.get('msg_mdify', { params }).then(res => res.data)
}
// 信息管理  删除
export const removeManageApi = params => {
    return axios.get('msg_remove', { params }).then(res => res.data)
}
// -------------------------------------------------------------------
// 活动设置   首页展示
export const getSetupApi = params => {
    return axios.get('active_index', { params }).then(res => res.data)
}
// 活动设置   添加修改展示
export const showSetupApi = params => {
    return axios.get('active_data', { params }).then(res => res.data)
}
// 活动设置   获取下拉
export const selSetupApi = params => {
    return axios.get('active_data_select', { params }).then(res => res.data)
}
// 活动设置   添加
export const addSetupApi = params => {
    return axios.get('active_add', { params }).then(res => res.data)
}
// 活动设置   修改
export const editSetupApi = params => {
    return axios.get('active_mdify', { params }).then(res => res.data)
}
// --------------------------------------------------------------------
// 活动价格   首页展示
export const getSetingApi = params => {
    return axios.get('active_price_index', { params }).then(res => res.data)
}
// 活动价格   添加修改展示
export const showSetingApi = params => {
    return axios.get('active_price_data', { params }).then(res => res.data)
}
// 活动价格   添加
export const addSetingApi = params => {
    return axios.get('active_price_add', { params }).then(res => res.data)
}
// 活动价格   添加修改展示
export const editSetingApi = params => {
    return axios.get('active_price_mdify', { params }).then(res => res.data)
}
// 活动信息  首页展示
export const getInforApi = params => {
    return axios.get('active_info_index', { params }).then(res => res.data)
}
// -------------------------------------------------------------------------
// 费用管理  首页展示
export const getPayApi = params => {
    return axios.get('financial_index', { params }).then(res => res.data)
}
// 费用管理  添加修改展示
export const showPayApi = params => {
    return axios.get('financial_data', { params }).then(res => res.data)
}
// 费用管理  下拉
export const selPayApi = params => {
    return axios.get('financial_data_select', { params }).then(res => res.data)
}
// 费用管理  添加
export const addPayApi = params => {
    return axios.get('financial_add', { params }).then(res => res.data)
}
// 费用管理  修改
export const editPayApi = params => {
    return axios.get('financial_mdify', { params }).then(res => res.data)
}
// ------------------------------------------------------------------------------
// 到校状态   首页展示
export const getadmissApi = params => {
    return axios.get('sch_s_index', { params }).then(res => res.data)
}
// 到校状态   图形展示
export const showadmissApi = params => {
    return axios.get('sch_s_view', { params }).then(res => res.data)
}
// 到校状态   下拉
export const seladmissApi = params => {
    return axios.get('sch_s_select', { params }).then(res => res.data)
}
// ------------------------------------------------------------------------------
// 缴费统计  首页展示
export const getCostApi = params => {
    return axios.get('financial_info_index', { params }).then(res => res.data)
}
// ---------------------------------------------------------------------
// 订单管理  首页展示
export const getOrderApi = params => {
    return axios.get('financial_order_index', { params }).then(res => res.data)
}
// ----------------------------------------------------------------------------
// 广告管理普通  首页展示
export const getPosterApi = params => {
    return axios.get('adver_index', { params }).then(res => res.data)
}
// 广告管理普通  首页展示状态改变
export const statePosterApi = params => {
    return axios.get('adver_change_status', { params }).then(res => res.data)
}
// 广告管理普通  首页删除
export const removePosterApi = params => {
    return axios.get('active_remove', { params }).then(res => res.data)
}
// 广告管理普通  数据展示
export const dataPosterApi = params => {
    return axios.get('active_view', { params }).then(res => res.data)
}
// ------------------------------------------------------------------
// 广告管理普通  添加修改数据展示
export const showPosterApi = params => {
    return axios.get('adver_data', { params }).then(res => res.data)
}
// 广告管理普通  添加修改获取市区
export const cityPosterApi = params => {
    return axios.get('adver_data_city', { params }).then(res => res.data)
}
// 广告管理普通  添加修改获取区域
export const areaPosterApi = params => {
    return axios.get('adver_data_area', { params }).then(res => res.data)
}
// 广告管理普通  添加修改获取广告位置
export const positPosterApi = params => {
    return axios.get('adver_data_position', { params }).then(res => res.data)
}
// 广告管理普通  添加修改获取广告
export const adverPosterApi = params => {
    return axios.get('adver_data_img', { params }).then(res => res.data)
}
// 广告管理普通  添加
export const addPosterApi = params => {
    return axios.get('adver_add', { params }).then(res => res.data)
}
// 广告管理普通  修改
export const editPosterApi = params => {
    return axios.get('adver_mdify', { params }).then(res => res.data)
}
// 广告管理普通   添加修改详情页
export const detialPosterApi = params => {
    return axios.get('adver_detial', { params }).then(res => res.data)
}
// ------------------------------------------------------------
// 广告管理高级   改变首页状态
export const stateAdverApi = params => {
    return axios.get('adver_admin_change_status', { params }).then(res => res.data)
}
// 广告管理高级   首页展示
export const getAdverApi = params => {
    return axios.get('adver_admin_index', { params }).then(res => res.data)
}
// 广告管理高级   修改点击量
export const editAdverApi = params => {
    return axios.get('active_admin_creat_data', { params }).then(res => res.data)
}
// 广告管理高级   数据展示
export const dataAdverApi = params => {
    return axios.get('active_admin_view', { params }).then(res => res.data)
}
// -----------------------------------------------------------------------
// 广告管理位置设置   首页展示
export const getPositApi = params => {
    return axios.get('adver_img_index', { params }).then(res => res.data)
}
// 广告管理位置设置   添加编辑获取数据
export const showPositApi = params => {
    return axios.get('adver_img_data', { params }).then(res => res.data)
}
// 广告管理位置设置   添加数据
export const addPositApi = params => {
    return axios.get('adver_img_add', { params }).then(res => res.data)
}
// 广告管理位置设置   编辑数据
export const editPositApi = params => {
    return axios.get('adver_img_mdify', { params }).then(res => res.data)
}
// 广告管理位置设置   删除数据
export const removePositApi = params => {
    return axios.get('active_img_remove', { params }).then(res => res.data)
}